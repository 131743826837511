#preloader {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
    height: 100%;
    width: 100%;
    background: #fff;
    display: flex;
}
.loader{
    margin: auto;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    position: relative;
}
.loader:before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #000;
    border-radius: 50%;
    opacity: 0;
    animation: popin 1.5s linear infinite 0s;
}
.loader:after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #000;
    border-radius: 50%;
    opacity: 0;
    animation: popin 1.5s linear infinite 0.5s;
}

@keyframes popin{
    0%{
        opacity: 0;
        transform: scale(0);
    }
    1%{
        opacity: 0.1;
        transform: scale(0);
    }
    99%{
        opacity: 0;
        transform: scale(2);
    }
    100%{
        opacity: 0;
        transform: scale(0);
    }
}