  .encabezado{
    background: #3489df;
    color: #fff;
    margin:1px;
    /*border:1px solid #000;*/
  }
  .color-1{
    background: #fff;
    color:#000;
  }
  .color-0{
    background: rgb(219, 212, 212);
    color:#000;
  }
  .color-0:hover,.color-1:hover{
    background: rgb(83, 77, 77);
    color: #fff;
  }
  .alineacion-vertical{
    align-self: center!important;
  }
  .img-hidden{
    display:none;
    position:absolute;
    right:15px;top:40px;
  }
  .loadData{
    z-index:9000;
    width:100%;
    height:100vh;
    position:fixed;
    top:0;
    left:0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.9);
  }
  
  /******************Contador de coincidencias*******************************/
  .card-counter{
    box-shadow: 2px 2px 10px #DADADA;
    margin: 5px;
    padding: 20px 10px;
    background-color: #fff;
    height: 100px;
    border-radius: 5px;
    transition: .3s linear all;
    position: relative;
  }
  
  .card-counter:hover{
    box-shadow: 4px 4px 20px #DADADA;
    transition: .3s linear all;
  }
  
  .card-counter.primary{
    background-color: #007bff;
    color: #FFF;
  }
  
  .card-counter.danger{
    background-color: #ef5350;
    color: #FFF;
  }  
  
  .card-counter.success{
    background-color: #66bb6a;
    color: #FFF;
  }  
  
  .card-counter.info{
    background-color: #26c6da;
    color: #FFF;
  }  
  
  .card-counter i{
    font-size: 5em;
    opacity: 0.2;
  }
  
  .card-counter .count-numbers{
    position: absolute;
    right: 65px;
    top: 25px;
    font-size: 32px;
    display: block;
  }
  
  .card-counter .count-name{
    position: absolute;
    right: 140px;
    top: 25px;
    font-style: italic;
    text-transform: capitalize;
    opacity: 0.5;
    display: block;
    font-size: 28px;
  }
  
  /******************Paginador*******************************/
  
  .paginacion{
    margin-bottom:15px;
    margin-top:15px
  }
  
  .paginacion ul{
    list-style:none;
    text-align:right;
    margin-left: -40px;
  }
  
  .paginacion ul li{
    display:inline-block;
    margin:0 1px;
    color:#fff;
    min-width: 42px;
    text-align: center;
    background:#595959;
    text-decoration:none;
    padding:6px 12px;
    border-radius:5px;
  }
  
  .paginacion ul li:hover{
    background:#bb1f35;
    cursor:pointer;
  }
  
  .paginacion ul .active{
    background:#037e8c;
    border:1px solid #fff;
    cursor:not-allowed;
    padding:6px 12px;
    border-radius:5px;
  }
  
  .paginacion ul .disabled , .paginacion ul .disabled:hover{
    background:#595959;
    cursor:not-allowed;opacity:.6;
  }

  .element-empty{
    display:inline-block;
  }
  
  @media (max-width: 991px) {
    .eliminar991,.ocultarColumna991{
      display: none;
      background: #fff;
    }
    .headMain{
      cursor: pointer;
    }
    .color-0:hover{
      background: rgb(219, 212, 212);
      color: #000;
    }
    .color-1:hover{
      background: rgb(243, 237, 237);
      color: #000;
    }
    .color-1{
      background: rgb(243, 237, 237);
      color: #000;
    }
    .ajuste-imagen{
      max-width: calc(100% - 150px);
    }
    
  }
  
  @media (min-width: 991px) {
    .ocultarLabel991{
        display: none;
        line-height: 6;
    }
    .contenedor-hijo{
      display: block!important;
    }
    .contenedor-hijo img{
      display: none!important;
    }
  }


  /*Autocomplete*/

  .container-autocomplete{
    position:relative;
  }
  

  .autocomplete{
    position: absolute;
    width: 100%;
    /* height: 200px; */

    background: #fff;
    z-index: 100;
    border:1px solid #007bff; 
    /* border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px; */
  }

  .item-list{
    padding: 6px;
  }

  .activeItem, .item-list:hover{
    background: #ccc;
  }
  
  .item-list:hover{
    cursor:pointer;
  }




